<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="store"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex">
            <div class="" style="margin: 0 auto; text-align: center">
              <h5 class="row title">
                <span style="margin: auto; text-align: center"
                  >Please Select Your Location</span
                >
              </h5>
              <p class="row subtitle" style="font-size: 13px; color: red">
                <span
                  >Product availability and fulfilment time may differ in
                  another store.</span
                >
              </p>
            </div>
            <button
              type="button"
              class="close float-right"
              style="margin: -1rem -1rem -1rem -1rem"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div id="store-tab">
              <button
                id="Pickup"
                @click.prevent="toggleMode('Pickup')"
                class="mode"
              >
                Free Pickup
              </button>

              <button
                id="Delivery"
                @click.prevent="toggleMode('Delivery')"
                class="mode"
              >
                Home Delivery
              </button>
            </div>

            <form autocomplete="off" method="POST" v-show="fulfilment_set">
              <div id="store-content">
                <div class="row">
                  <div class="col-sm-6 drop_down_wrap">
                    <v-select
                      :options="cities"
                      v-model="city"
                      placeholder="Kindly select city"
                      class="form-group"
                    >
                      <span slot="no-options">{{ city_note }}</span>
                    </v-select>
                  </div>
                  <div class="col-sm-6 drop_down_wrap">
                    <v-select
                      :options="areas"
                      v-model="area"
                      class="form-group"
                      placeholder="Kindly select area"
                    >
                      <span slot="no-options">{{ area_note }}</span>
                    </v-select>
                  </div>
                </div>
                <div
                  class="d-md-flex justify-content-between mt-3"
                  v-if="stores.length > 0"
                >
                  <h5 class="label">Select Store</h5>
                  <div class="store-search">
                    <input
                      v-model="search"
                      type="text"
                      name=""
                      id=""
                      placeholder="Search for stores"
                      autocomplete="off"
                    />
                    <i class="material-icons">search</i>
                  </div>
                </div>
                <div class="row my-2" v-if="stores.length > 0">
                  <div
                    v-for="store in filteredList"
                    v-bind:key="store.name"
                    class="col-sm-6 store-box"
                  >
                    <div
                      v-if="
                        store.has_prepared_food == 1 &&
                        store.launched_preparedfood == 1
                      "
                      class="d-flex"
                      @click.prevent="saveStore(store)"
                    >
                      <img src="../assets/img/prepared.png" alt="" />

                      <img
                        class="cartn"
                        src="../assets/img/shopping-cart.png"
                        alt=""
                      />
                      <img
                        class="card-hover"
                        src="../assets/img/shopping-cart-hover.png"
                        alt=""
                      />

                      <p class="storename">{{ camelCase(store.name) }}</p>

                      <i class="fa fa-long-arrow-right mt-3 ml-lg-4"></i>
                    </div>
                    <div
                      v-else
                      class="d-flex"
                      @click.prevent="saveStore(store)"
                    >
                      <img
                        class="cartn"
                        src="../assets/img/shopping-cart.png"
                        alt=""
                      />
                      <img
                        class="card-hover"
                        src="../assets/img/shopping-cart-hover.png"
                        alt=""
                      />

                      <p class="storename">{{ camelCase(store.name) }}</p>
                      <i class="fa fa-long-arrow-right mt-3 ml-lg-4"></i>
                    </div>
                  </div>
                </div>
                <div v-if="area !== '' && stores.length == 0">
                  <div class="text-center my-2">
                    We are currently not processing orders in your area. Kindly
                    view other areas to see the areas that we serve.
                  </div>
                </div>
                <div v-if="nooption" class="my-2">
                  <div class="text-center">
                    <!--Our online stores are currently unavailable-->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
export default {
  name: "StoreSelector",
  props: {
    msg: String,
  },
  data() {
    return {
      fulfilment_set: "",
      method: "",
      city: "",
      area: "",
      store: null,
      search: "",
      nooption: false,
      city_note: "Loading, please wait...",
      area_note: "Loading, please wait...",
      all_stores: [],
      stores: [],
      stores_id: [],
      zones: [],
      areas: [],
      cities: [],
      choosestore: false,
      storename: "",
    };
  },
  mounted() {
    this.fetchStores();
    // console.log(this.$store.getters.isStoreSet)
    if (this.$store.getters.stat_stores == false) {
      this.fetchStores();
    } else {
      this.all_stores = this.$store.getters.allstores;
    }

    $("#store").on("show.bs.modal", this.doSomething);
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  computed: {
    filteredList() {
      return this.stores.filter((store) => {
        if (store.name.toLowerCase().includes(this.search.toLowerCase())) {
          return store.name.toLowerCase().includes(this.search.toLowerCase());
        } else {
          return store.city.toLowerCase().includes(this.search.toLowerCase());
        }
      });
    },
  },
  watch: {
    method: function (val) {
      let vm = this;
      vm.city = "";
      vm.cities = [];
      vm.area = "";
      vm.stores_id = [];
      if (val == "Pickup") {
        vm.all_stores.forEach((i) => {
          if (i.store_options != null && i.store_options.pickup == 1) {
            console.log(vm.zones);
            vm.zones.forEach((x) => {
              x.areas.forEach((j) => {
                j.store.forEach((k) => {
                  if (i.id == k) {
                    if (!vm.cities.includes(x.city)) {
                      let show = vm.cities.push(x.city);
                    }
                  }
                });
              });
            });
          }
        });
        if (vm.cities.length === 0 && vm.all_stores.length > 0) {
          vm.city_note = "No matching options";
        }
      } else if (val == "Delivery") {
        vm.all_stores.forEach((i) => {
          if (i.store_options != null && i.store_options.delivery == 1) {
            vm.zones.forEach((x) => {
              x.areas.forEach((j) => {
                j.store.forEach((k) => {
                  if (i.id == k) {
                    if (!vm.cities.includes(x.city)) {
                      vm.cities.push(x.city);
                    }
                  }
                });
              });
            });
          }
        });
        if (vm.cities.length === 0 && vm.all_stores.length > 0) {
          vm.city_note = "No matching options";
        }
      }
      vm.cities.sort();
    },
    city: function (val) {
      let vm = this;
      vm.areas = [];
      vm.stores_id = [];
      vm.area = "";
      vm.zones.forEach((i) => {
        if (i.city == val) {
          i.areas.forEach((j) => {
            if (j.store.length > 0) {
              j.store.forEach((k) => {
                vm.all_stores.forEach((l) => {
                  if (k == l.id) {
                    if (vm.method == "Pickup" && l.store_options.pickup == 1) {
                      if (!vm.areas.includes(j.area)) {
                        vm.areas.push(j.area);
                      }
                    } else if (
                      vm.method == "Delivery" &&
                      l.store_options.delivery == 1
                    ) {
                      if (!vm.areas.includes(j.area)) {
                        vm.areas.push(j.area);
                      }
                    } else {
                    }
                  }
                });
              });
            }
          });
        }
      });
      vm.areas.sort();
      if (vm.areas.length === 0) {
        vm.area_note = "No matching options";
      }
      // console.log(vm.areas)
    },
    area: function (val) {
      // console.log(val)
      this.$store.dispatch("area", val);
      let vm = this;
      vm.stores_id = [];
      let stores = [];
      vm.zones.forEach((i) => {
        i.areas.forEach((j) => {
          if (j.area == val) {
            j.store.forEach((k) => {
              if (!stores.includes(k)) {
                stores.push(k);
              }
            });
            vm.stores_id = stores;
          }
        });
      });
    },

    stores_id: function (val) {
      this.stores = [];
      let vm = this;
      this.all_stores.forEach((i) => {
        val.forEach((j) => {
          if (i.id == j) {
            if (vm.method == "Pickup" && i.store_options.pickup == 1) {
              this.stores.push(i);
            } else if (
              vm.method == "Delivery" &&
              i.store_options.delivery == 1
            ) {
              this.stores.push(i);
            } else {
            }
          }
        });
      });
    },
  },
  methods: {
    fetchCategories() {
      let req = {
        what: "getCategories",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((response) => {
          if (response.type == "getCategories") {
            // this.categories = response.data.data
            this.$store.dispatch("categories", response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchPromotions() {
      let req = {
        what: "getPromotions",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((response) => {
          if (response.type == "getPromotions") {
            // this.categories = response.data.data
            this.$store.dispatch(
              "promotions",
              response.data.data.filter((val) => val != null)
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    doSomething($event) {
      this.fetchAreas();
      let stat = [];
      this.all_stores.forEach((i) => {
        if (
          i.store_options == null ||
          (i.store_options.pickup != "1" && i.store_options.delivery != "1")
        ) {
          stat.push(false);
        } else {
          stat.push(true);
        }
      });

      if (!stat.includes(true)) {
        this.nooption = true;
      }
    },
    fetchAreas() {
      let req = {
        what: "areas",
        showLoader: false,
      };
      this.$request
        .makeGetRequest(req)
        .then((response) => {
          console.log(response.data);
          this.zones = response.data.data;
          this.method = "Delivery";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchStores() {
      let req = {
        what: "stores",
      };
      this.$request
        .makeGetRequest(req)
        .then((response) => {
          this.$store
            .dispatch("setAllStores", response.data.stores)
            .then((re) => {
              this.$store.dispatch("setStatStore", true);
              this.all_stores = response.data.stores;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    camelCase(str) {
      var arr = new Array();
      let xstr;
      str.split(" ").map(function (word, index) {
        arr[index] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        str = str.replace(word, arr[index]);
      });
      return str;
    },
    toggleMode(method) {
      this.fulfilment_set = true;
      this.method = method;
      let mode = document.querySelectorAll(".mode");
      let meth = document.getElementById(method);
      mode.forEach((el) => {
        el.classList.remove("active");
      });
      if (!meth.classList.contains("active")) {
        meth.classList.add("active");
      }
    },
    saveStore(store) {
      let oldstore = this.$store.getters.store.name;
      store.mode = this.method;

      this.$store.dispatch("setStoreStatus", true);
      // this.$store.dispatch("setBlackFriday", false);

      this.$store.dispatch("setStore", store).then((res) => {
        if (
          window.location.pathname != "/home" ||
          window.location.pathname != "/"
        ) {
          this.fetchCategories();
          this.fetchPromotions();
        }

        if (oldstore !== store.name) {
          //this.$store.dispatch('addToCart', []);

          let old_cart_tray = this.$store.getters.cart;

          if (old_cart_tray.length > 0) {
            let store__id = this.$store.getters.store.id;
            let cart__arr = [];

            let i;
            for (i = 0; i < old_cart_tray.length; i++) {
              cart__arr.push(old_cart_tray[i].product.id);
            }

            let cart__info = { cart: cart__arr, store_id: store__id };
            var req = {
              what: "check_cart_instore",
              showLoader: false,
              data: cart__info,
            };

            this.$request
              .makePostRequest(req)
              .then((response) => {
                let product = response.data.data;

                this.$store.dispatch("addToCart", []);

                let cart = {
                  product: {},
                };

                let cart_array = [];

                let i;
                for (i = 0; i < product.length; i++) {
                  cart.quantity = 1;
                  cart.unit_price = product[i].sellingprice;
                  cart.price = product[i].sellingprice * cart.quantity;
                  cart.product.id = product[i].id;
                  cart.product.name = product[i].name;
                  cart.product.price = product[i].sellingprice;
                  cart.product.img_url = product[i].img_url;

                  cart_array.push(cart);

                  cart = {
                    product: {},
                  };
                }

                this.$store.dispatch("addToCart", cart_array);
                location.reload();
              })
              .catch((error) => {
                this.$store.dispatch("addToCart", []);
              });
          } else {
            location.reload();
          }
        }

        if (window.location.pathname == "/storeslist") {
          $(".modal").modal("hide");
          this.$router.push("home");
          // location.reload()
        } else if (window.location.pathname == "/") {
          $(".modal").modal("hide");
          if (this.$store.getters.categoryRoute == null) {
            this.$router.push("home");
          }
          this.$router.push("home");
          //this.$router.push(this.$store.getters.categoryRoute);
          //this.$router.go();
        } else {
          $(".modal").modal("hide");
          // this.$router.go()
        }
      });
    },
  },
};
</script>

<style scoped>
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
  padding: 10px;
  border: 1px solid lightgrey;
}

@media screen and (max-width: 500px) {
  .vs__dropdown-menu {
    max-height: 150px !important;
    overflow-y: scroll !important;
  }
  #store #store-content {
    margin: 5% 1% !important;
  }
}
.shop {
  /*font-size: 50px !important;*/
}
.card-hover {
  display: none;
}
</style>
